import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LangService } from "src/app/_service/lang.service";

@Component({
  selector: "app-whatnews",
  templateUrl: "./whatnews.component.html",
  styleUrls: ["./whatnews.component.css"],
})
export class WhatnewsComponent implements OnInit {
  modelDataNew: any[] = [
    {
      title:
        this.langService.currLang() === "en"
          ? 'Siamese Asset PCL signed a memorandum of understanding (MOU) for collaboration on the \"Zero Construction Wastes and Upcycling Products\" project in partnership with Ritta Co., Ltd. and the Faculty of Engineering, Chulalongkorn University'
          : 'บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ลงนามบันทึกข้อตกลงความร่วมมือ โครงการ Zero Construction Wastes and Upcycling Products ร่วมกับ บริษัท ฤทธา จำกัด และ คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย',
      desc:
        this.langService.currLang() === "en"
          ? 'On December 11, 2024, Siamese Asset PCL, represented by Mrs. Sunanta Singsansern, Director and Chief Operating Officer, and Mr. Monthien Yingdamnoon, Director and Chief Business Development Officer, joined hands with Ritta Co., Ltd., represented by Mr. Panitan Tepnikorn, Director and Co-Chief Executive Officer, and Mr. Phetshrat Phetdee, Executive Director and General Manager. They collaborated with the Faculty of Engineering, Chulalongkorn University, represented by Assoc. Prof. Witaya Wannasuphoprasit, Ph.D., Dean of the Faculty of Engineering, Assoc. Prof. Jenyuk Lohwacharin, Ph.D., Head of the Department of Environmental and Sustainable Engineering, Prof. Kasem Choocharukul, Ph.d., Head of the Department of Civil Engineering, along with Assoc. Prof. Nopdanai Ajavakom, Ph.D., Deputy Dean, Asst. Prof. Surat Kwanmuang, Ph.D., Deputy Dean, Assoc. Prof. Manaskorn Rachakornkij, Ph.D., Assoc. Prof. Dao Suwansang Janjaroen. Ph.D. and Chitiphon Chuaicham, Ph.D., with honored guests.'
          : 'เมื่อวันที่ 11 ธันวาคม 2567 บริษัท ไซมิส แอสเสท จำกัด (มหาชน) โดยนางสุนันทา สิ่งสรรเสริญ กรรมการบริษัทและประธานเจ้าหน้าที่ฝ่ายปฏิบัติการ และนายมณเฑียร ยิ่งดำนุ่น กรรมการบริษัทและประธานเจ้าหน้าที่ฝ่ายพัฒนาธุรกิจ ร่วมกับ บริษัท ฤทธา จำกัด โดยคุณปณิธาน&nbsp; เทพนิกร กรรมการบริษัทและประธานเจ้าหน้าที่บริหารร่วม และคุณเพชรรัตน์ เพชรดี กรรมการบริหารและผู้จัดการทั่วไป และคณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย โดย รศ.ดร.วิทยา วัณณสุโภประสิทธิ์ คณบดี คณะวิศวกรรมศาสตร์ รศ.ดร.เจนยุกต์ โล่วัชรินทร์ หัวหน้าภาควิชาวิศวกรรมสิ่งแวดล้อมและความยั่งยืน',
      img:
        this.langService.currLang() === "en"
          ? "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-12/20241212-01.jpg"
          : "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-12/20241212-01.jpg",
    },
    {
      title:
        this.langService.currLang() === "en"
          ? "SA Appoints Knightsbridge Partners as the Master Agency for the International Market, Introducing the Landmark at MRTA Station Project Targeting Branded Residence and Investment Program Buyers"
          : "SA แต่งตั้ง Knightsbridge Partners เป็นตัวแทนขายหลักตลาดต่างชาติ ส่งโครงการ Landmark at MRTA Station เจาะกลุ่มลูกค้า Branded Residence และ Investment Program",
      desc:
        this.langService.currLang() === "en"
          ? "Mr. Kajonsit Singsansern, CEO, and Mrs. Sunanta Singsansern, COO of Siamese Asset Public Company Limited (SA), a leading real estate developer, continue to expand into the international market by appointing Knightsbridge Partners as the master agent and primary sales representative for the international quota of the Landmark at MRTA Station"
          : "นายขจรศิษฐ์ สิ่งสรรเสริญ ประธานเจ้าหน้าที่บริหาร และนางสุนันทา สิ่งสรรเสริญ ประธานเจ้าหน้าที่ฝ่ายปฏิบัติการ บริษัท &nbsp;ไซมิส แอสเสท จำกัด (มหาชน) หรือ SA ผู้พัฒนาอสังหาริมทรัพย์ชั้นนำ เดินหน้ารุกตลาดต่างชาติอย่างต่อเนื่อง แต่งตั้ง บริษัท ไนท์สบริดจ์ พาร์ทเนอร์ จำกัด (Knightsbridge Partners) เป็น Master Agent ตัวแทนขายหลัก สำหรับโควต้าต่างชาติของโครงการ Landmark at MRTA Station ชูจุดขายคอนโดมิเนียมที่พักอาศัยแบบ Branded Residence ภายใต้ชื่อ Cassia Residence ที่มาพร้อมกับมาตรฐานการบริการระดับโรงแรม Cassia ในเครือ Banyan Group",
      img:
        this.langService.currLang() === "en"
          ? "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-11/news-20241129-01.jpg"
          : "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-11/news-20241129-01.jpg",
    },
    {
      title:
        this.langService.currLang() === "en"
          ? "Mitihoon to Host Investors and Explore Real Estate Investment Opportunities"
          : "SIAMESE ASSET ร่วมกับทีมมิติหุ้น เปิดบ้านต้อนรับนักลงทุน เพิ่มโอกาสการลงทุนในรูปแบบอสังหาริมทรัพย์",
      desc:
        this.langService.currLang() === "en"
          ? "Siamese Asset Public Company Limited, a leading Thai real estate developer, joined forces with Mitihoon to warmly welcome investors and offer them a deeper understanding of investment opportunities in Siamese Asset's real estate projects."
          : "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ผู้นำด้านการพัฒนาอสังหาริมทรัพย์ไทย ร่วมกับทีมมิติหุ้น เปิดบ้านต้อนรับนักลงทุนอย่างอบอุ่น เพื่อเปิดโอกาสให้เหล่านักลงทุนได้เข้าใจทิศทางการลงทุนในรูปแบบอสังหาริมทรัพย์ของบริษัท ไซมิส แอสเสท จำกัด (มหาชน) อย่างลึกซึ้งยิ่งขึ้น",
      img:
        this.langService.currLang() === "en"
          ? "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-09/news-20240924-01.jpg"
          : "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-09/news-20240924-01.jpg",
    } 
  ];

  modelData: any[] = [
    {
      title: "Blossom Condo",
      line1: "Sathorn-Charoeneat",
      line2: "",
      line3: "",
      line4: "",
      isOver: false,
    },
    {
      title: "Blossom Condo",
      line1: "Condo Fashion Beyord",
      line2: "",
      line3: "",
      line4: "",
      isOver: false,
    },
  ];
  constructor(public router: Router, public langService: LangService) {}

  ngOnInit() {}

  goto(page) {
    this.router.navigate(["/news-detail"], { queryParams: { id: page } });
  }

  gotonews() {
    this.router.navigate(["/news"]);
  }
}
