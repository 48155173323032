import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { LangService } from "src/app/_service/lang.service";

@Component({
  selector: "app-about-history",
  templateUrl: "./about-history.component.html",
  styleUrls: ["./about-history.component.css"],
})
export class AboutHistoryComponent implements OnInit {
  activeSlideIndex: number;
  backgroundImgs: "";
  model: any = {
    month: "",
    year: "",
  };
  modelYear: any[] = [
    {
      year: this.langService.currLang() === "en" ? "2010" : "2553",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital 10 MB."
              : "ทุนจดทะเบียน 10 ล้านบาท",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Established on March 11, 2010"
              : "ก่อตั้งเมื่อวันที่ 11 มีนาคม 2553",
          desc:
            this.langService.currLang() === "en"
              ? '- Established on March 11, 2010. The company is created under the motto "Asset of Life" and has been engaging in the real estate development and constructions businesses.'
              : "- ก่อตั้งเมื่อวันที่ 11 มีนาคม 2553 เพื่อพัฒนาโครงการอสังหาริมทรัพย์ด้วยปรัชญาที่ว่า  “Asset of Life”",
        },
      ],
      backgroundImg: "assets/images/about/history/2010.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2011" : "2554",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Gioia Project"
              : "โครงการไซมิส จอยย่า",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on January 30, 2011, the project contains 168 units with 925.6 MB project value."
              : "- เปิดตัวโครงการ วันที่ 30 มกราคม 2554 มูลค่าโครงการ 925.6 ล้านบาท จำนวน 168 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Thirty Nine Project"
              : "โครงการไซมิส เธอร์ตี้ ไนน์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on November 26, 2011, the project contains 87 units with 603.2 MB project value."
              : "- เปิดตัวโครงการ วันที่ 26 พฤศจิกายน 2554 มูลค่าโครงการ 603.2 ล้านบาท จำนวน 87 ยูนิต ",
        },
      ],
      backgroundImg: "assets/images/about/history/2011.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2012" : "2555",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Ratcha Kru Project"
              : "โครงการไซมิส ราชครู",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on April 21, 2012, the project contains 285 units with 1,652.9 MB project value."
              : "- เปิดตัวโครงการ วันที่ 21 เมษายน 2555 มูลค่าโครงการ 1,652.9 ล้านบาท จำนวน 285 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Blossom @ Fashion Plot B - Townhome Project"
              : "โครงการไซมิส บลอสซั่ม แอท แฟชั่น พล็อต บี - ทาวน์โฮม",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on December 9, 2012, the project contains 40 units with 209.2 MB project value."
              : "- เปิดตัวโครงการ วันที่ 9 ธันวาคม 2555 มูลค่าโครงการ 209.2 ล้านบาท จำนวน 40 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2012.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2013" : "2556",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 200 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 200 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Blossom @ Fashion Plot C - Townhouse Phase 1 Project"
              : "โครงการไซมิส บลอสซั่ม แอท แฟชั่น พล็อต ซี - ทาวน์เฮ้าส์ เฟส 1",
          // tslint:disable-next-line: max-line-length
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on January 12, 2013, the project contains 120 units with 792.1 MB project value."
              : "- เปิดตัวโครงการ วันที่ 12 มกราคม 2556 มูลค่าโครงการ 792.1 ล้านบาท จำนวน 120 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Blossom @ Fashion Plot B - Shophouse Project"
              : "โครงการไซมิส บลอสซั่ม แอท แฟชั่น พล็อต บี - ช็อปเฮ้าส์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on April 20, 2013, the project contains 8 units with 68.6 MB project value."
              : "- เปิดตัวโครงการ วันที่ 20 เมษายน 2556 มูลค่าโครงการ 68.6 ล้านบาท จำนวน 8 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Nang Linchee Project"
              : "โครงการไซมิส นางลิ้นจี่",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on August 25, 2013, the project contains 214 units with 929.5 MB project value."
              : "- เปิดตัวโครงการ วันที่ 25 สิงหาคม 2556 มูลค่าโครงการ 929.5 ล้านบาท จำนวน 214 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Surawong Project"
              : "โครงการไซมิส สุรวงศ์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on September 14, 2013, the project contains 206 units with 1,546.9 MB project value."
              : "- เปิดตัวโครงการ วันที่ 14 กันยายน 2556 มูลค่าโครงการ 1,546.9 ล้านบาท จำนวน 209 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2013.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2014" : "2557",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 280 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 280 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese District Project - Office Block"
              : "โครงการไซมิส ดิสทริค - อาคารพาณิชย์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on January 1, 2014, the project contains 16 units with 337.1 MB project value."
              : "- เปิดตัวโครงการ วันที่ 1 มกราคม 2557 มูลค่าโครงการ 337.1 ล้านบาท จำนวน 16 ยูนิต ",
        },
      ],
      backgroundImg: "assets/images/about/history/2014.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2015" : "2558",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 705 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 705 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Blossom Condo @ Fashion Altitude Project"
              : "โครงการบลอสซั่ม คอนโด แอท แฟชั่น อัลทิทูดย์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on June 13, 2015, the project contains 326 units with 756.9 MB project value."
              : "- เปิดตัวโครงการ วันที่ 13 มิถุนายน 2558 มูลค่าโครงการ 756.9 ล้านบาท จำนวน 326 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Exclusive 31 Project"
              : "โครงการไซมิส เอ๊กซ์คลูซีพ 31",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on August 8, 2015, the project contains 193 units with 2,633.5 MB project value."
              : "- เปิดตัวโครงการ วันที่ 8 สิงหาคม 2558 มูลค่าโครงการ 2,633.5 ล้านบาท จำนวน 193 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Blossom Condo @ Sathorn-Charoenrat Project"
              : "โครงการบลอสซั่ม คอนโด แอท สาทร-เจริญราษฎร์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on September 16, 2015, the project contains 792 units with 2,883.0 MB project value."
              : "- เปิดตัวโครงการ วันที่ 16 กันยายน 2558 มูลค่าโครงการ 2,883.0 ล้านบาท จำนวน 792 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2015.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2016" : "2559",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 880,247,100 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 880,247,100 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Blossom @ Fashion Plot C - Home Office Project"
              : "โครงการไซมิส บลอสซั่ม แอท แฟชั่น พล็อต ซี - โฮม ออฟฟิศ",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on October 13, 2016, the project contains 45 units with 227.7 MB project value."
              : "- เปิดตัวโครงการ วันที่ 31 ตุลาคม 2559 มูลค่าโครงการ 227.7 ล้านบาท จำนวน 45 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Sukhumvit 48 Project"
              : "โครงการไซมิส สุขุมวิท 48",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on November 13, 2016, the project contains 358 units with 2,782.2 MB project value."
              : "- เปิดตัวโครงการ วันที่ 13 พฤศจิกายน 2559 มูลค่าโครงการ 2,782.2 ล้านบาท จำนวน 358 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2016.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2017" : "2560",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 923,504,300 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 923,504,300 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Exclusive 42 Project"
              : "โครงการไซมิส เอ๊กซ์คลูซีพ 42",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on March 25, 2017, the project contains 454 units with 3,520.4 MB project value."
              : "- เปิดตัวโครงการ วันที่ 25 มีนาคม 2560 มูลค่าโครงการ 3,520.4 ล้านบาท จำนวน 449 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Blossom Condo @ Fashion Beyond Project"
              : "โครงการบลอสซั่ม คอนโด แอท แฟชั่น บียอนน์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on August 5, 2017, the project contains 453 units with 1,150.7 MB project value."
              : "- เปิดตัวโครงการ วันที่ 5 สิงหาคม 2560 มูลค่าโครงการ 1,150.7 ล้านบาท จำนวน 453 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Kin Ramintra Project"
              : "โครงการไซมิส คิน รามอินทรา",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on November 18, 2017, the project contains 71 units with 814.35 MB project value."
              : "- เปิดตัวโครงการ วันที่ 18 พฤศจิกายน 2560 มูลค่าโครงการ 814.35 ล้านบาท จำนวน 71 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2017.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2018" : "2561",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 961,547,300 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 961,547,300 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Exclusive Queens Project"
              : "โครงการไซมิส เอ๊กซ์คลูซีพ ควีนส์",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on January 20, 2018, the project contains 332 units with 4,426.7 MB project value."
              : "- เปิดตัวโครงการ วันที่ 20 มกราคม 2561 มูลค่าโครงการ 4,426.7 ล้านบาท จำนวน 332 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Sukhumvit 87 Project"
              : "โครงการไซมิส สุขุมวิท 87",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on March 1, 2018, the project contains 373 units with 2,279.1 MB project value."
              : "- เปิดตัวโครงการ วันที่ 1 มีนาคม 2561 มูลค่าโครงการ 2,279.1 ล้านบาท จำนวน 373 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "The Collection Project"
              : "โครงการเดอะ คอลเลคชั่น",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on November 24, 2018, the project contains 332 units with 5,748.6 MB project value."
              : "- เปิดตัวโครงการ วันที่ 24 พฤศจิกายน 2561 มูลค่าโครงการ 5,748.6 ล้านบาท จำนวน 332 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2018.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2019" : "2562",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Company Limited transformed into a public limited company"
              : "บริษัท ไซมิส แอสเสท จำกัด แปรสภาพเป็นบริษัทมหาชนจำกัด",
          desc:
            this.langService.currLang() === "en"
              ? "registered capital 1,281,547,300 MB on October 7, 2019."
              : "ด้วยทุนจดทะเบียน 1,281,547,300 ล้านบาท เมื่อวันที่ 7 ตุลาคม 2562",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Exclusive Ratchada Project"
              : "โครงการไซมิส เอ๊กซ์คลูซีพ รัชดา",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on February 23, 2019, the project contains 662 units with 3,903.7 MB project value."
              : "- เปิดตัวโครงการ วันที่ 23 กุมภาพันธ์ 2562 มูลค่าโครงการ 3,903.7 ล้านบาท จำนวน 622 ยูนิต",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Rama 9 Project"
              : "โครงการไซมิส พระราม 9",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on July 20, 2018, the project contains 2,011 units with 11,378.5 MB project value."
              : "- เปิดตัวโครงการ วันที่ 20 กรกฎาคม 2562 มูลค่าโครงการ 11,378.5 ล้านบาท จำนวน 2,011 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2019.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2020" : "2563",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ยื่นแบบคำขออนุญาตเสนอขายหลักทรัพย์ และแบบแสดงรายการข้อมูลการเสนอขายหลักทรัพย์ (แบบไฟลิ่ง) ต่อสำนักงาน ก.ล.ต.",
          desc:
            this.langService.currLang() === "en"
              ? "Open for trading in the SET for the first day on December 25, 2020"
              : "เปิดซื้อขายหลักทรัพย์วันแรก 25 ธันวาคม 2563",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "เปิดตัวโครงการ BRANDED RESIDENCE วันที่ 10 มีนาคม 2563",
          desc:
            this.langService.currLang() === "en"
              ? "- Wyndham Residence (Siamese Exclusive Queens)"
              : "- วินแดม เรสซิเดนซ์(ไซมิส เอก๊กซ์คลูซีพ ควีนส์)",
        },
      ],
      backgroundImg: "assets/images/about/history/2019.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2020" : "2563",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ยื่นแบบคำขออนุญาตเสนอขายหลักทรัพย์ และแบบแสดงรายการข้อมูลการเสนอขายหลักทรัพย์ (แบบไฟลิ่ง) ต่อสำนักงาน ก.ล.ต.",
          desc:
            this.langService.currLang() === "en"
              ? "Open for trading in the SET for the first day on December 25, 2020"
              : "เปิดซื้อขายหลักทรัพย์วันแรก 25 ธันวาคม 2563",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "เปิดตัวโครงการ BRANDED RESIDENCE วันที่ 10 มีนาคม 2563",
          desc:
            this.langService.currLang() === "en"
              ? "- Wyndham Garden Residence (Siamese Exclusive 42)"
              : "- วินแดม การ์เด้น เรสซิเดนซ์ (ไซมิส เอ๊กซ์คลูซีพ 42)",
        },
      ],
      backgroundImg: "assets/images/about/history/2019.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2020" : "2563",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ยื่นแบบคำขออนุญาตเสนอขายหลักทรัพย์ และแบบแสดงรายการข้อมูลการเสนอขายหลักทรัพย์ (แบบไฟลิ่ง) ต่อสำนักงาน ก.ล.ต.",
          desc:
            this.langService.currLang() === "en"
              ? "Open for trading in the SET for the first day on December 25, 2020"
              : "เปิดซื้อขายหลักทรัพย์วันแรก 25 ธันวาคม 2563",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "เปิดตัวโครงการ BRANDED RESIDENCE วันที่ 10 มีนาคม 2563",
          desc:
            this.langService.currLang() === "en"
              ? "- Ramada Plaza Residence (Siamese Sukhumvit 48)"
              : "- รามาดา พลาซา เรสซิเดนซ์ (ไซมิส สุขุมวิท 48)",
        },
      ],
      backgroundImg: "assets/images/about/history/2019.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2020" : "2563",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ยื่นแบบคำขออนุญาตเสนอขายหลักทรัพย์ และแบบแสดงรายการข้อมูลการเสนอขายหลักทรัพย์ (แบบไฟลิ่ง) ต่อสำนักงาน ก.ล.ต.",
          desc:
            this.langService.currLang() === "en"
              ? "Open for trading in the SET for the first day on December 25, 2020"
              : "เปิดซื้อขายหลักทรัพย์วันแรก 25 ธันวาคม 2563",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Asset Public Company Limited, submitted a filing to the Stock Exchange."
              : "เปิดตัวโครงการ BRANDED RESIDENCE วันที่ 10 มีนาคม 2563",
          desc:
            this.langService.currLang() === "en"
              ? "- Ramada Residence (Siamese Sukhumvit 87)"
              : "- รามาดา เรสซิเดนซ์ (ไซมิส สุขุมวิท 87)",
        },
      ],
      backgroundImg: "assets/images/about/history/2019.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2021" : "2564",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 1,200,650,454 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 1,200,650,454 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Landmark @Grand Station"
              : "โครงการ แลนด์มาร์ค แอท แกรนด์ สเตชั่น",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on June 26, 2021, the project contains 1,232 units with 4,357.5 MB project value"
              : "- เปิดตัวโครงการ วันที่ 26 มิถุนายน 2564 มูลค่าโครงการ 4,357.5 ล้านบาท จำนวน 1,232 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2021.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2022" : "2565",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Registration capital increased to 1,775,683,850 MB."
              : "ทุนจดทะเบียนเพิ่มขึ้นเป็น 1,775,683,850 ล้านบาท",
          desc: "",
        },
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Landmark @Kaset Station"
              : "โครงการ แลนด์มาร์ค แอท เกษตร ทุ่งสองห้อง สเตชั่น",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on April 2,2022, the project contain 428 units with 1,650.10 MB project value"
              : "- เปิดตัวโครงการวันที่ 2 เมษายน 2565 มูลค่าโครงการ 1,650.10 ล้านบาท จำนวน 428 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/home/Section2/Preview-11.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2022" : "2565",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Monsane Exclusive Villa Ratchapruek-Pinklao Project"
              : "โครงการ มนต์เสน่ห์ เอ๊กซ์คลูซีพ วิลล่า ราชพฤกษ์-ปิ่นเกล้า",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on November 26,2022, the project contain 59 units with 1,972.22 MB project value"
              : "- เปิดตัวโครงการวันที่ 26 พฤศจิกายน 2565 มูลค่าโครงการ 1,972.22 ล้านบาท จำนวน 59 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2565left.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2023" : "2566",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Kin Ramintra Phase 2 Project"
              : "โครงการ ไซมิส คิน รามอินทรา เฟส 2",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on 18 March 2023, the project contains 36 units with 279.2 MB project value"
              : "- เปิดตัวโครงการ  18 มีนาคม 2566 มูลค่าโครงการ 279.2 ล้านบาท จำนวน 36 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2566right.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2023" : "2566",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Holm Phahol-Vibhavadi"
              : "โครงการ ไซมิส โฮล์ม พหลฯ-วิภาวดี",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on 21 March 2023, the project contains 192 units with 1,793.69 MB project value"
              : "- เปิดตัวโครงการ 21 มีนาคม 2566 มูลค่าโครงการ 1,793.69 ล้านบาท จำนวน 192 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2566right.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2023" : "2566",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Siamese Blossom Phahol-Vibhavadi"
              : "โครงการ ไซมิส บลอสซั่ม พหลฯ-วิภาวดี",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on 21 March 2023, the project contains  445 units with 1,732.02 MB project value"
              : "- เปิดตัวโครงการ 21 มีนาคม 2566 มูลค่าโครงการ 1,732.02ล้านบาท จำนวน 445 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2566right.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2023" : "2566",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Monsane Ratchapruek-Chaeng Watthana"
              : "โครงการ มนต์สเน่ห์ ราชพฤกษ์-แจ้งวัฒนะ",
          desc:
            this.langService.currLang() === "en"
              ? "- Launched on 3 May 2023, the project contains 175 units with 3,3001.35 MB project value"
              : "- เปิดตัวโครงการ 3 พฤษภาคม 2566 มูลค่าโครงการ 3,301.35 ล้านบาท จำนวน 175 ยูนิต",
        },
      ],
      backgroundImg: "assets/images/about/history/2566right.jpg",
    },
    {
      year: this.langService.currLang() === "en" ? "2024" : "2567",
      isSelect: false,
      detail: [
        {
          headerDesc:
            this.langService.currLang() === "en"
              ? "Tribe Living Bangkok Sukhumvit 39"
              : "Tribe Living Bangkok Sukhumvit 39",
          desc:
            this.langService.currLang() === "en"
              ? "- Tribe Living Bangkok Sukhumvit 39 opened for service on February 1, 2024"
              : "- เปิดให้บริการ Tribe Living Bangkok Sukhumvit 39 เมื่อวันที่ 1 กุมภาพันธ์ 2567",
        },
      ],
      backgroundImg: "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/history/history-2024-bk.jpg",
    },
  ];

  yearSelect: any = {
    year: "",
  };

  modalTab: any = {
    tab1: false,
    tab2: false,
  };

  constructor(public langService: LangService) {}

  ngOnInit() {
    const yearSelect = this.langService.currLang() === "en" ? "2010" : "2553";
    this.selectYear(yearSelect);
    this.model.year = yearSelect;
    this.modalTab.tab1 = true;
  }

  setYear(obj) {
    // this.modelYear.forEach((element) => {
    //   element.isSelect = false;
    // });

    return obj.year === this.yearSelect.year;
  }

  selectYear(inputYear) {
    this.yearSelect.year = inputYear;
    const index = this.modelYear.findIndex((x) => x.year === inputYear);
    this.activeSlideIndex = index;
    this.model.year = inputYear;
    this.modelYear.forEach((obj) => {
      obj.isSelect = false;
      if (obj.year === this.yearSelect.year) {
        obj.isSelect = true;
        const objhtm = document.querySelector(".app-alerts") as HTMLElement;
        objhtm.style.backgroundImage = "url(" + obj.backgroundImg + ")";
        objhtm.style.height = "800px";
        objhtm.style.backgroundSize = "cover";
        objhtm.style.backgroundPosition = "center";
      }
    });
  }

  log(event: number) {
    this.yearSelect.year = this.modelYear[event].year;
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;

    if (selectTab === 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab === 2) {
      this.modalTab.tab2 = true;
    }
  }
}
