import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild,
} from "@angular/animations";
import { OwlOptions } from "ngx-owl-carousel-o";
import sampleData from "../../../assets/data/activites.json";
import { Router } from "@angular/router";
import { LangService } from "src/app/_service/lang.service.js";
@Component({
  selector: "app-activities",
  templateUrl: "./activities.component.html",
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ transform: "scale(0.5)", opacity: 0 }), // initial
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ), // final
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1, height: "*" }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({
            transform: "scale(0.5)",
            opacity: 0,
            height: "0px",
            margin: "0px",
          })
        ),
      ]),
    ]),
    trigger("list", [
      transition(":enter", [
        query("@items", stagger(300, animateChild()), { optional: true }),
      ]),
    ]),
  ],
  styleUrls: ["./activities.component.css"],
})
export class ActivitiesComponent implements OnInit {
  model: any = {
    month: "",
    year: "",
  };

  modelBtnFilter: any = {
    filter: "",
  };

  modelProjectsFiltter: any[] = [];

  modelProjects: any[] = sampleData;
  page = 1;
  // lang = this.langService.currLang().toLocaleLowerCase();
  modelHilight: any = {};

  constructor(private router: Router, public langService: LangService) {}

  ngOnInit() {
    // this.modelProjectsFiltter = this.paginate(this.modelProjects, 4, this.page);
    const curDate = new Date();
    //this.model.month = ('0' + (curDate.getMonth() + 1)).slice(-2);
    //this.model.year = curDate.getFullYear();
    this.model.month = "";
    this.model.year = "";
    this.filter(this.model.month, this.model.year);

    this.modelHilight = {
      titleEn:
        "The executives and employees of Siamese Asset Public Company Limited and its subsidiaries hosted a lunch and donated scholarships, supplies, and medical items to the students at the cha-am school for the blind with multiple disabilities",
      titleTh:
        "ผู้บริหารและพนักงาน บริษัท ไซมิส แอสเสท จำกัด (มหาชน) และบริษัทในเครือ เลี้ยงอาหารกลางวัน มอบเงินทุนการศึกษา สิ่งของเครื่องใช้และเวชภัณฑ์ ให้กับน้อง ๆ โรงเรียนการศึกษาเด็กตาบอดพิการซ้ำซ้อน ชะอำ",
      descEn:
        'On December 13, 2024, executives and employees of Siamese Asset PCL and its subsidiaries traveled to host a lunch for the students of The cha-am school for the blind with multiple disabilities. They also donated scholarships, supplies, as well as medicines and medical items. The event was led by Mrs. Sunanta Singsansern, Chief Operating Officer, along with over 300 employees from various business units. The school was welcomed by Ms. Pornthip Kiatnaruemon, Foundation Director of the Dhammakaya Foundation for the Blind in Thailand under the Royal Patronage, Cha-Am Branch, along with the staff and students',
      descTh:
        "เมื่อวันที่ 13 ธันวาคม 2567ผู้บริหารและพนักงานบริษัท ไซมิส แอสเสท จำกัด (มหาชน) และบริษัทในเครือ ได้เดินทางไปเลี้ยงอาหารกลางวันน้อง ๆ พร้อมมอบเงินทุนการศึกษา สิ่งของเครื่องใช้ รวมถึงยาและเวชภัณฑ์ต่าง ๆ ในกับน้อง ๆ โรงเรียนการศึกษาเด็กตาบอดพิการซ้ำซ้อน ชะอำ นำโดยคุณสุนันทา สิ่งสรรเสริญ ประธานเจ้าหน้าที่ฝ่ายปฏิบัติการ พร้อมพนักงานในทุกกลุ่มธุรกิจกว่า 300 คนเข้าร่วมกิจกรรม โดยมีคุณพรทิพย์ เกียรตินฤมล ผู้อำนวยการมูลนิธิธรรมิกชน เพื่อคนตาบอดในประเทศไทย ในพระบรมราชูปถัมภ์ สาขาชะอำ พร้อมเจ้าหน้าที่และน้อง ๆ นักเรียนให้การต้อนรับ",
      imgEn: "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-12/20241220-01.jpg",
      imgTh: "https://siamese-corp.sgp1.cdn.digitaloceanspaces.com/news/2024-12/20241220-01.jpg",
    };
  }

  filter(month, year) {
    this.page = 1;
    this.modelProjectsFiltter = [];
    // tslint:disable-next-line: only-arrow-functions
    this.modelProjectsFiltter = this.modelProjects
      .filter(function (el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.activitesDate < b.activitesDate
          ? 1
          : b.activitesDate < a.activitesDate
          ? -1
          : 0
      );

    this.modelProjectsFiltter = this.paginate(
      this.modelProjectsFiltter,
      4,
      this.page
    );
  }

  paginate(array, pageSize, pageNumber) {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  viewMore(month, year) {
    this.page = this.page + 1;

    const dataview = this.modelProjects
      .filter(function (el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.activitesDate < b.activitesDate
          ? 1
          : b.activitesDate < a.activitesDate
          ? -1
          : 0
      );

    const resp = this.paginate(dataview, 4, this.page);
    resp.forEach((item) => {
      this.modelProjectsFiltter.push(item);
    });
  }

  goto(page) {
    this.router.navigate(["/news-detail"], { queryParams: { id: page } });
  }
}


// ,
//   {
//     "imageUrl": "./assets/images/news/2023/10/06-01.jpg",
//     "credit": "บริษัท เอ็ม ที มัลติมีเดีย จำกัด (ในนาม บมจ. ไซมิส แอทเสท)",
//     "year": "2023",
//     "month": "10",
//     "date": "06",
//     "headerTh": "บริษัท ไซมิส แอสเสท จำกัด (มหาชน) ลงนามบันทึกข้อตกลงความร่วมมือทางวิชาการร่วมกับ 9 ราชมงคล",
//     "headerEn": "Siamese Asset PCL signing an academic cooperation agreement with The 9 Rajamangala University of Technology",
//     "Id": "169",
//     "activitesDate": "2023-10-06",
//     "pdfFile": ""
//   }